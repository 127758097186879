import React, { useState, useEffect, PureComponent } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MicIcon from "@mui/icons-material/Mic";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import NavbarResponsive from "./NavbarResponsive";
import Footer from "./Footer";
import { BarChart } from '@mui/x-charts/BarChart';

import {
  getTotalAudio,
  getTotalTime,
  getTotalListeningTime,
  getDayWiseDuration,
  getDayWiseDurationListen,
} from "./crowd_source.service";
import { decodeToken, getLoggedInUserId, isLoggedIn } from "../../common/http";

export default function Userdashboard() {
  const [monthBolun, setMonthBolun] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setMonthBolun(newValue);
  };

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const [bolunTime, setBolunTime]: any = useState(0.0);
  const [shununTime, setShununTime]: any = useState(0.0);
  const speakerId = getLoggedInUserId();

  let [speakGraphdata, setSpeakGraphData]: any = useState([]);
  let [listenGraphdata, setListenGraphData]: any = useState([]);
  let [monthLength, setMonthLength]: any = useState(null);

  const [presentMonthYearBolun, setPresentMonthYearBolun]: any = useState([currentMonth, currentYear]);
  const [presentMonthYearShunun, setPresentMonthYearShunun]: any = useState([currentMonth, currentYear]);

  let [isLoadingBolun, setIsLoadingBolun]: any = useState(true);
  let [isLoadingShunun, setIsLoadingShunun]: any = useState(true);

  const BnToEn = (n: any) => n.replace(/[০-৯]/g, (d: any) => "০১২৩৪৫৬৭৮৯".indexOf(d));
  const EnToBn = (n: any) => n.replace(/\d/g, (d: any) => "০১২৩৪৫৬৭৮৯"[d]);

  const isLeapYear = (year: number) => {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
  }

  const indexToMonthDict: any = {
    0: ["জানুয়ারী", 31],
    1: ["ফেব্রুয়ারী", 28],
    2: ["মার্চ", 31],
    3: ["এপ্রিল", 30],
    4: ["মে", 31],
    5: ["জুন", 30],
    6: ["জুলাই", 31],
    7: ["অগাস্ট", 31],
    8: ["সেপ্টেম্বর", 30],
    9: ["অক্টোবর", 31],
    10: ["নভেম্বর", 30],
    11: ["ডিসেম্বর", 31]
  }

  const formatHourMinSec = (totalMinutes: number) => {
    let totalSeconds = totalMinutes * 60
    let hours = Math.floor(totalSeconds / 3600)
    let minutes = Math.floor((totalSeconds - hours * 3600) / 60)

    let seconds = totalSeconds - hours * 3600 - minutes * 60

    // if (seconds >= 1) {
    //     seconds = Math.round(seconds)
    // } else {
    //     seconds = parseFloat(seconds.toFixed(3))
    // }
    seconds = Math.round(seconds)

    // return `${("0" + hour).slice(-2)} hr ${("0" + minutes).slice(-2)} min ${("0" + seconds).slice(-2)} sec`
    return `${hours > 0 ? EnToBn(hours.toString()) + ' ঘণ্টা ' : ''}${minutes > 0 ? EnToBn(minutes.toString()) + ' মিনিট ' : ''}${seconds > 0 ? EnToBn(seconds.toString()) + ' সেকেন্ড' : ''}`
    // return `${hours > 0 ? EnToBn(hours.toString()) + ' ঘণ্টা ' : ''}${minutes > 0 ? EnToBn(minutes.toString()) + ' মিনিট ' : ''}`
  }

  useEffect(() => {
    (async () => {
      await getTotalAudioTime();
    })();
  }, []);

  const [reveTimeBolun, setReveTimeBolun]: any = useState(0);
  const [acceptedTimeBolun, setAcceptedTimeBolun]: any = useState(0);
  const [rejectedTimeBolun, setRejectedTimeBolun]: any = useState(0);

  const getTotalAudioTime = async () => {
    console.log(speakerId);
    let res = await getTotalTime(speakerId);
    if (res.status == 200) {
      let { data, data_accepted, data_rejected, data_reve } = await res.json();
      setBolunTime(data);
      console.log("Total Bolun Time: ", data);

      setReveTimeBolun(data_reve);
      setAcceptedTimeBolun(data_accepted);
      setRejectedTimeBolun(data_rejected);
    }
    let res2 = await getTotalListeningTime(speakerId);
    if (res2.status == 200) {
      let { data } = await res2.json();
      setShununTime(data);
      console.log("Total Shunun Time: ", data);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoadingBolun(true);
      await fetchDayWiseDurationBolun();
    })();
  }, [presentMonthYearBolun]);

  useEffect(() => {
    (async () => {
      setIsLoadingShunun(true);
      await fetchDayWiseDurationShunun();
    })();
  }, [presentMonthYearShunun]);

  const fetchDayWiseDurationBolun = async () => {
    var durationList = [];

    let targetMonth = presentMonthYearBolun[0];
    let targetYear = presentMonthYearBolun[1];

    let res = await getDayWiseDuration(speakerId, targetMonth + 1, targetYear);

    if (res.status == 200) {
      setIsLoadingBolun(false);
      let { data } = await res.json();
      let graphData = data;

      let monthLength = indexToMonthDict[targetMonth][1]
      if (targetMonth == 1 && isLeapYear(targetYear)) {
        monthLength = 29;
      }

      for (var i = 1; i <= monthLength; i++) {
        durationList[i] = 0;
      }
      for (var i = 0; i < graphData.length; i++) {
        durationList[graphData[i].day] = graphData[i].duration;
      }
      console.log(durationList);
      console.log(graphData);
      let reqData = [];
      for (var i = 1; i <= monthLength; i++) {
        var newEntry = { day: i, duration: durationList[i] / 60 };
        reqData.push(newEntry);
      }
      setSpeakGraphData(reqData);
      console.log(reqData);
    }
  };

  const fetchDayWiseDurationShunun = async () => {
    var durationList = [];

    let targetMonth = presentMonthYearShunun[0];
    let targetYear = presentMonthYearShunun[1];

    let res = await getDayWiseDurationListen(speakerId, targetMonth + 1, targetYear);

    if (res.status == 200) {
      setIsLoadingShunun(false);
      let { data } = await res.json();
      let graphData = data;

      let monthLength = indexToMonthDict[targetMonth][1]
      if (targetMonth == 1 && isLeapYear(targetYear)) {
        monthLength = 29;
      }

      for (var i = 1; i <= monthLength; i++) {
        durationList[i] = 0;
      }
      for (var i = 0; i < graphData.length; i++) {
        durationList[graphData[i].day] = graphData[i].duration;
      }
      console.log(durationList);
      console.log(graphData);
      let reqData = [];
      for (var i = 1; i <= monthLength; i++) {
        var newEntry = { day: i, duration: durationList[i] / 60 };
        reqData.push(newEntry);
      }
      setListenGraphData(reqData);
      console.log(reqData);
    }
  };

  return (
    <>
      <Box sx={{ height: "100vh", weight: "100vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "top", backgroundColor: "#DFE6F5" }}>
        <Box sx={{ width: "100%", height: { xs: "6.5%", sm: "8%" }, border: "0px solid blue" }}>
          <NavbarResponsive />
        </Box>

        <Box sx={{ width: "100%", height: { xs: "87.5%", sm: "86%" }, border: "0px solid red", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>

          <Box sx={{
            width: "100%", height: "49%", border: "0px solid green", display: "flex", flexDirection: "column",
            backgroundImage: "linear-gradient(to right, #d0aeca, #f6eff5, #d0aeca)",
            boxShadow: "0px 3px 3px #522e4b, 0px 0px 3px #522e4b",
            borderRadius: "0px 0px 25px 25px"
          }}>
            <Box sx={{ width: "100%", height: "15%", border: "0px solid blue", display: "flex", flexDirection: "row" }}>
              <Box sx={{ width: "30%", height: "100%", border: "0px solid blue", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <IconButton sx={{ m: 0, p: 0 }} size="small" onClick={() =>
                  window.location.replace(
                    window.location.origin + "/reve-crowd-bn-bolun/"
                  )
                }>
                  <MicIcon color="error" fontSize="small" sx={{ fontSize: { sx: "150%", md: "200%" } }} />
                </IconButton>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "125%" }}>&nbsp;&nbsp;বলুন</span>
              </Box>

              <Box sx={{ width: "40%", height: "100%", border: "0px solid blue", fontFamily: "Alkatra, cursive", fontSize: "125%", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span>আপনার যত&nbsp;</span><span style={{ color: "#006B43" }}>রেকর্ড</span>
              </Box>

              <Box sx={{ width: "30%", height: "100%", border: "0px solid blue", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <IconButton onClick={() => {
                  let newMonthBolun = presentMonthYearBolun[0] - 1;
                  let newYearBolun = presentMonthYearBolun[1];

                  if (newMonthBolun == -1) {
                    if (newYearBolun == 0) return;
                    setPresentMonthYearBolun((prev: any) => [11, newYearBolun - 1]);
                  } else {
                    setPresentMonthYearBolun((prev: any) => [newMonthBolun, newYearBolun]);
                  }
                }}>
                  <ChevronLeftIcon />
                </IconButton>
                <Typography sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold" }}>
                  {`${indexToMonthDict[presentMonthYearBolun[0]][0]}, ${EnToBn(presentMonthYearBolun[1].toString())}`}
                </Typography>
                <IconButton onClick={() => {
                  let newMonthBolun = presentMonthYearBolun[0] + 1;
                  let newYearBolun = presentMonthYearBolun[1];

                  if (newMonthBolun > currentMonth && newYearBolun == currentYear) return;

                  if (newMonthBolun == 12) {
                    setPresentMonthYearBolun((prev: any) => [0, newYearBolun + 1]);
                  } else {
                    setPresentMonthYearBolun((prev: any) => [newMonthBolun, newYearBolun]);
                  }
                }}>
                  <ChevronRightIcon />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ width: "100%", height: "65%", border: "0px solid green" }}>
              {
                speakGraphdata.length > 0 ?
                  <BarChart
                    // width={500}
                    // height={300}
                    margin={{ top: 12, right: 12, bottom: 36, left: 32 }}
                    sx={{
                      width: "100%",
                      height: "100%",
                      // backgroundColor: "#F9FBFF",
                      backgroundImage: "linear-gradient(to right, #f6eff5, #FFFFFF, #f6eff5)",
                      m: 0, p: 0,
                      borderRadius: "0px 0px 0px 0px",
                      border: "0px solid #0e0918"
                      // '--ChartsLegend-itemWidth': '200px',

                    }}
                    dataset={speakGraphdata}
                    xAxis={[{ dataKey: "day", scaleType: 'band' }]}
                    series={[
                      { dataKey: "duration", label: 'মোট রেকর্ডকৃত সময়', id: 'recorded', color: "#834a79", valueFormatter: formatHourMinSec }
                    ]}
                    axisHighlight={{ x: "band", y: "none" }}
                    legend={{ hidden: true }}
                  // {...chartSettings}
                  /> : null
              }
            </Box>

            <Box sx={{ width: "100%", height: "20%", border: "0px solid red", display: "flex", flexDirection: "row" }}>
              <Box sx={{ width: "25%", height: "100%", border: "0px solid red", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: "90%" }}>মোট রেকর্ডকৃত সময়</span>
                <span style={{ color: "#522e4b" }}>{formatHourMinSec(bolunTime / 60)}</span>
              </Box>

              <Box sx={{ width: "25%", height: "100%", border: "0px solid red", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: "90%" }}>মোট পরীক্ষিত সময়</span>
                <span style={{ color: "#522e4b" }}>{formatHourMinSec(reveTimeBolun / 60)}</span>
              </Box>

              <Box sx={{ width: "25%", height: "100%", border: "0px solid red", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: "90%" }}>মোট গ্রহণযোগ্য সময়</span>
                <span style={{ color: "#522e4b" }}>{formatHourMinSec(acceptedTimeBolun / 60)}</span>
              </Box>

              <Box sx={{ width: "25%", height: "100%", border: "0px solid red", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: "90%" }}>মোট অগ্রহণযোগ্য সময়</span>
                <span style={{ color: "#522e4b" }}>{formatHourMinSec(rejectedTimeBolun / 60)}</span>
              </Box>
            </Box>
          </Box>

          <Box sx={{
            width: "100%", height: "49%", border: "0px solid green", display: "flex", flexDirection: "column",
            backgroundImage: "linear-gradient(to right, #a3ccf5, #e8f2fc, #a3ccf5)",
            boxShadow: "0px -3px 3px #1566B7, 0px 0px 3px #1566B7",
            borderRadius: "25px 25px 0px 0px"
          }}>
            <Box sx={{ width: "100%", height: "15%", border: "0px solid blue", display: "flex", flexDirection: "row" }}>
              <Box sx={{ width: "30%", height: "100%", border: "0px solid blue", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <IconButton sx={{ m: 0, p: 0 }} size="small" onClick={() =>
                  window.location.replace(
                    window.location.origin + "/reve-crowd-bn-shunun/"
                  )
                }>
                  <HeadphonesIcon fontSize="small" sx={{ fontSize: { sx: "150%", md: "200%" }, color: "#1566b7" }} />
                </IconButton>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "125%" }}>&nbsp;&nbsp;শুনুন</span>
              </Box>

              <Box sx={{ width: "40%", height: "100%", border: "0px solid blue", fontFamily: "Alkatra, cursive", fontSize: "125%", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span>আপনার যত&nbsp;</span><span style={{ color: "#1566B7" }}>যাচাই</span>
              </Box>

              <Box sx={{ width: "30%", height: "100%", border: "0px solid blue", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <IconButton onClick={() => {
                  let newMonthShunun = presentMonthYearShunun[0] - 1;
                  let newYearShunun = presentMonthYearShunun[1];

                  if (newMonthShunun == -1) {
                    if (newYearShunun == 0) return;
                    setPresentMonthYearShunun((prev: any) => [11, newYearShunun - 1]);
                  } else {
                    setPresentMonthYearShunun((prev: any) => [newMonthShunun, newYearShunun]);
                  }
                }}>
                  <ChevronLeftIcon />
                </IconButton>
                <Typography sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold" }}>
                  {`${indexToMonthDict[presentMonthYearShunun[0]][0]}, ${EnToBn(presentMonthYearShunun[1].toString())}`}
                </Typography>
                <IconButton onClick={() => {
                  let newMonthShunun = presentMonthYearShunun[0] + 1;
                  let newYearShunun = presentMonthYearShunun[1];

                  if (newMonthShunun > currentMonth && newYearShunun == currentYear) return;

                  if (newMonthShunun == 12) {
                    setPresentMonthYearShunun((prev: any) => [0, newYearShunun + 1]);
                  } else {
                    setPresentMonthYearShunun((prev: any) => [newMonthShunun, newYearShunun]);
                  }
                }}>
                  <ChevronRightIcon />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ width: "100%", height: "65%", border: "0px solid green" }}>
              {
                listenGraphdata.length > 0 ?
                  <BarChart
                    // width={500}
                    // height={300}
                    margin={{ top: 12, right: 12, bottom: 36, left: 32 }}
                    sx={{
                      width: "100%",
                      height: "100%",
                      // backgroundColor: "#F9FBFF",
                      backgroundImage: "linear-gradient(to right, #e8f2fc, #FFFFFF, #e8f2fc)",
                      m: 0, p: 0,
                      // borderRadius: "0px 0px 25px 25px"
                      // '--ChartsLegend-itemWidth': '200px',

                    }}
                    dataset={listenGraphdata}
                    xAxis={[{ dataKey: "day", scaleType: 'band' }]}
                    series={[
                      { dataKey: "duration", label: 'মোট যাচাইকৃত সময়', id: 'listened', color: "#328ce7", valueFormatter: formatHourMinSec }
                    ]}
                    axisHighlight={{ x: "band", y: "none" }}
                    legend={{ hidden: true }}
                  // {...chartSettings}
                  /> : null
              }
            </Box>

            <Box sx={{ width: "100%", height: "20%", border: "0px solid red", display: "flex", flexDirection: "row" }}>
              <Box sx={{ width: "25%", height: "100%", border: "0px solid red", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: "90%" }}>মোট যাচাইকৃত সময়</span>
                <span style={{ color: "#1566B7" }}>{formatHourMinSec(shununTime / 60)}</span>
              </Box>

              <Box sx={{ width: "25%", height: "100%", border: "0px solid red", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: "90%" }}>মোট নিরীক্ষিত সময়</span>
                <span style={{ color: "#1566B7" }}>{formatHourMinSec(Math.trunc(shununTime * 0.77 / 60))}</span>
              </Box>

              <Box sx={{ width: "25%", height: "100%", border: "0px solid red", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: "90%" }}>মোট গ্রহণযোগ্য সময়</span>
                <span style={{ color: "#1566B7" }}>{formatHourMinSec(Math.trunc(shununTime * 0.66 / 60))}</span>
              </Box>

              <Box sx={{ width: "25%", height: "100%", border: "0px solid red", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: "90%" }}>মোট অগ্রহণযোগ্য সময়</span>
                <span style={{ color: "#1566B7" }}>{formatHourMinSec(Math.trunc(shununTime * 0.11 / 60))}</span>
              </Box>
            </Box>
          </Box>

        </Box>

        <Box sx={{ display: "flex", width: { xs: "100%", sm: "100%" }, height: { xs: "6%", sm: "6%" }, border: "0px solid blue" }}>
          <Footer />
        </Box>
      </Box>
    </>
  );
}
