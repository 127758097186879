import HeadphonesIcon from "@mui/icons-material/Headphones";
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

import LaunchIcon from "@mui/icons-material/Launch";
import MicIcon from "@mui/icons-material/Mic";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import PublishIcon from "@mui/icons-material/Publish";
import ReplayIcon from "@mui/icons-material/Replay";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { Box, Link, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import AudioSpectrogram from './AudioSpectrogram';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import { getAudioAndScriptsByUserName } from "./crowd_source.service";

import Menu from "@mui/material//Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useRef, useState } from "react";
import { decodeToken } from "../../common/http";
import { ToastFailedMsg, ToastSuccessMsg, ToastWarningMsg } from "../../common/toast";
import BoldMiddleText from "./BoldMiddleText";
import stopImage from "./images/stop.gif";

const TTS_ELIGIBLE_LISTENER_LIST = [
  "ashir940917@gmail.com",
  "ashikshafat12@gmail.com",
  "ashikshafat11@gmail.com",
  "mobasshera.islam.du@gmail.com",
  "listenertts@gmail.com"
]
const ACCEPT_REASONS = ["সঠিক", "বাইরে শব্দ", "মৃদু নয়েজ"];
const REJECT_REASONS = [
  "অপ্রমিত",
  "স্ক্রিপ্ট-স্পিচে অমিল",
  "অযাচিত পজ",
  "বিরাম প্রয়োজন",
  "খুব দ্রুত",
  "বেশি স্লো",
  "বেশি লাউড",
  "ভলিউম কম",
  "নয়েজ",
  "হাঁচি/কাশি/শ্বাস",
  "অযাচিত আওয়াজ",
  "ফিলার পজ",
  "অসম্পূর্ণ",
  "পুনরাবৃত্তি",
  "প্রতিধ্বনি",
  "যান্ত্রিক শব্দ",
  "প্রতিকূল পরিবেশ",
  "আপ-ডাউন",
  "অন্যান্য",
];

function ShununComponent() {
  const [openBackdrop, setOpenBackdrop] = useState(true);
  let [remainingSpeech, setRemainingSpeech]: any = useState(0);
  const [playingIndex, setPlayingIndex] = useState<number>(-1);
  const [scripts, setScripts]: any = useState([]);
  const scriptsRef = useRef([]);
  const [eligibleSpeakers, setEligibleSpeakers]: any = useState([]);
  const [index, setIndex] = useState<number>(0);
  const [anchorElAccept, setAnchorElAccept] = useState(null);
  const [anchorElReject, setAnchorElReject] = useState(null);
  const [selectedSpeaker, setSelectedSpeaker]: any = useState(localStorage.getItem("selectedSpeaker") ? localStorage.getItem("selectedSpeaker") : null);
  const [hasListened, setHasListened]: any = useState(false);
  const [openStates, setOpenStates]: any = useState(Array(5).fill(false));
  const [reVerdict, setReVerdict]: any = useState(false);
  const [isPlaying, setIsPlaying]: any = useState(false);
  const [openSpectrogram, setOpenSpectrogram]: any = useState(false);
  const verdictCount = useRef(0);
  const wavesurferInstance: any = useRef(null);

  const userInfo: any = useRef({});

  useEffect(() => {
    userInfo.current = decodeToken();
    console.log("user Info", userInfo.current);

    if (!(userInfo.current && TTS_ELIGIBLE_LISTENER_LIST.includes(userInfo.current.userId))) {
      ToastWarningMsg("আপনার ID যাচাইকারীদের তালিকায় নেই ! আপনার যাচাই বাতিল গণ্য হতে পারে ! অ্যাডমিনকে জানান !")
    }

    (async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders
      };

      let response = await fetch("https://amarkantho.revesoft.com:5931/getEligibleSpeakers/", requestOptions)
      if (response.status == 200 || response.status == 201) {
        let result = await response.json()
        setOpenBackdrop(false);
        setEligibleSpeakers(result.sort());
        // ToastSuccessMsg(result.message);
      } else {
        let error = await response.json()
        setOpenBackdrop(false);
        console.log("error", error);
        ToastFailedMsg(error.message);
      }
    })();

    // Event listener for Ctrl + X to toggle spectrogram
    const handleKeyDown = (event: any) => {
      if (event.ctrlKey && event.key === 'x') {
        event.preventDefault();
        if (scriptsRef.current.length > 0 && verdictCount.current < scriptsRef.current.length) {
          setOpenSpectrogram(true);
        }
      }
    };

    // Add event listener on component mount
    window.addEventListener('keydown', handleKeyDown);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    console.log("scripts..........", scripts);
    scriptsRef.current = scripts;
  }, [scripts]);

  useEffect(() => {
    // console.log("ELIGIBLE SPEAKERS: ", eligibleSpeakers);
  }, [eligibleSpeakers]);

  useEffect(() => {
    console.log("SELECTED SPEAKER: ", selectedSpeaker);
    if (selectedSpeaker !== null && selectedSpeaker != '') {
      localStorage.setItem("selectedSpeaker", selectedSpeaker);
      (async () => await ResetAll())();
    } else {
      localStorage.removeItem("selectedSpeaker");
      (async () => await ResetAll())();
    }
  }, [selectedSpeaker]);

  let closeSpectrogram = () => {
    if (wavesurferInstance.current) {
      wavesurferInstance.current.destroy();
      wavesurferInstance.current = null;
    }
    setOpenSpectrogram(false);
  }

  function handleClickAccept(event: any) {
    if (anchorElAccept !== event.currentTarget) {
      setAnchorElAccept(event.currentTarget);
    }
  }

  function handleClickReject(event: any) {
    if (anchorElReject !== event.currentTarget) {
      setAnchorElReject(event.currentTarget);
    }
  }

  function handleCloseAccept(event: any) {
    // console.log("event is ....", event);
    setAnchorElAccept(null);
  }
  function handleCloseReject(event: any) {
    // console.log("event is ....", event);
    setAnchorElReject(null);
  }

  const handleItemClick = (type: any, itm: any) => {
    let indx = playingIndex !== -1 ? playingIndex : index;

    setScripts(
      scripts.map((item: any, idx: any) => {
        if (idx === indx) {
          return { ...item, verdict: `${type}|${itm}` };
        } else {
          return item;
        }
      })
    );
    setHasListened(false);
    if (!reVerdict) {
      setIndex((preIndex: any) => preIndex + 1);
      verdictCount.current += 1;
    } else {
      setReVerdict(false);
    }
    setPlayingIndex(-1);
  };

  const playAudio = () => {
    let idx = playingIndex !== -1 ? playingIndex : index;
    scripts[idx].audioElement.play();
    scripts[idx].audioElement.onended = () => {
      setIsPlaying(false);
      setHasListened(true);
    };
  };

  const getAudioScriptByUserId = async () => {
    setOpenBackdrop(true);
    if (userInfo.current) {
      console.log(selectedSpeaker, userInfo.current.userId);
      if (selectedSpeaker == null || selectedSpeaker === "") {
        setOpenBackdrop(false);
        ToastWarningMsg("বক্তা নির্বাচন করুন !");
        setScripts([]);
        return
      };

      if (selectedSpeaker === userInfo.current.userId) {
        setOpenBackdrop(false);
        ToastFailedMsg("নিজের অডিও নিজে যাচাই নিষেধ !");
        return
      };

      let res = await getAudioAndScriptsByUserName(
        selectedSpeaker,
        userInfo.current.userId
      );
      console.log("status ", res.status);
      if (res.status === 200) {
        let { data, remaining_speech, listened_speech } = await res.json();
        setOpenBackdrop(false);
        console.log("Total File ", remaining_speech, data);
        setRemainingSpeech(remaining_speech);
        // setReadScripts(listened_speech);
        setScripts(
          data.map((item: any) => {
            return { ...item, audioElement: new Audio(item.voiceUrl) };
          })
        );
      } else {
        setOpenBackdrop(false);
      }
    } else {
      setOpenBackdrop(false);
      ToastFailedMsg("লগইন করুন !");
    }
  };

  // const options = eligibleSpeakers.map((option:any) => {
  //   const firstLetter = option[0].toUpperCase();
  //   return {
  //     firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
  //     ...option,
  //   };
  // });

  const handleTooltipClick = (index: any) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = !newOpenStates[index];
    setOpenStates(newOpenStates);
  };

  const ResetAll = async () => {
    setOpenBackdrop(false);
    setPlayingIndex(-1);
    setIndex(0);

    setAnchorElAccept(null);
    setAnchorElReject(null);

    setHasListened(false);
    setOpenStates(Array(5).fill(false));
    setReVerdict(false);
    setIsPlaying(false);
    verdictCount.current = 0;

    if (selectedSpeaker == null || selectedSpeaker == "") {
      setScripts([]);
    } else {
      await getAudioScriptByUserId();
    }
  };

  const onSubmit = async () => {
    setOpenBackdrop(true);
    console.log("INSIDE SUBMIT .........................");

    try {
      if (userInfo.current) {
        let requestBody: any = {
          "listener_id": userInfo.current.userId
        };
        let verdictList: any = [];

        scripts.forEach((script: any) => {
          if ("verdict" in script) {
            verdictList.push({
              "speech_id": script.id,
              "duration": script.duration,
              "is_eligible": script["verdict"].split("|")[0] == "YES" ? true : false,
              "reason": script["verdict"].split("|")[1]
            });
          }
        });
        requestBody["verdicts"] = verdictList;
        console.log(requestBody)

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let requestOptions: RequestInit = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(requestBody),
          cache: "no-cache"
        };

        let response = await fetch(
          "https://amarkantho.revesoft.com:5922/submit-listen/",
          requestOptions
        )

        if (response.status == 200 || response.status == 201) {
          let result = await response.json();
          console.log(result);
          setOpenBackdrop(false);
          ToastSuccessMsg(result.message);
          await ResetAll();
        } else {
          let error = await response.json();
          console.log("error", error);
          setOpenBackdrop(false);
          ToastFailedMsg(error.message);
        }
      } else {
        setOpenBackdrop(false);
        ToastFailedMsg("Please Login First !")
      }
    } catch (err: any) {
      setOpenBackdrop(false);
      console.error(`${err.name}: ${err.message}`);
      ToastFailedMsg(err.message);
    }
  };


  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          border: "0px solid green",
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%" },
          height: { xs: "100%", sm: "100%" },
          mx: "auto",
        }}
      >
        {/*............. Upper Part Starts,............................... */}
        <Box
          sx={{
            border: "0px solid green",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: { xs: "8%", sm: "12%" },
            width: "100%",
            marginBottom: { xs: "0px", sm: "10px" }
          }}
        >
          <Box sx={{ border: "0px solid green", display: "flex", width: { xs: "25%", sm: "25%" }, justifyContent: "left", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton onClick={() =>
                window.location.replace(
                  window.location.origin + "/reve-crowd-bn-bolun/"
                )
              }>
                <MicIcon color="error" fontSize="large" />
              </IconButton>
              <Typography
                sx={{
                  color: "#6F7D99",
                  fontWeight: "bold",
                  display: { xs: "none", sm: "block" },
                  fontFamily: "Noto Serif Bengali, serif"
                }}
              >
                বলুন
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() =>
                  window.location.replace(
                    window.location.origin + "/reve-crowd-bn-shunun/"
                  )
                }
              >
                <HeadphonesIcon fontSize="large" color="primary" />
              </IconButton>
              <Typography
                sx={{
                  color: "#6F7D99",
                  fontWeight: "bold",
                  display: { xs: "none", sm: "block" },
                  fontFamily: "Noto Serif Bengali, serif"
                }}
              >
                শুনুন
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", width: { xs: "50%", sm: "25%" }, justifyContent: "center", alignItems: "center", gap: 2, border: "0px solid green" }}>
            <Tooltip arrow={true} title="যাচাই বাকী" componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.black',
                  '& .MuiTooltip-arrow': {
                    color: 'common.black',
                  },
                  color: "#FFF",
                  fontFamily: "Noto Serif Bengali, serif",
                  fontWeight: "bold",
                  fontSize: "75%"
                }
              }
            }}>
              <IconButton sx={{
                p: 0, m: 0, fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
                fontSize: "100%"
              }} color="error">
                {remainingSpeech}
              </IconButton>
            </Tooltip>

            {/* <Tooltip arrow={true} title="যাচাই শেষ" componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.black',
                  '& .MuiTooltip-arrow': {
                    color: 'common.black',
                  },
                  color: "#FFF",
                  fontFamily: "Noto Serif Bengali, serif",
                  fontWeight: "bold",
                  fontSize: "75%"
                }
              }
            }}>
              <IconButton sx={{
                p: 0, m: 0, fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
                fontSize: "100%"
              }} color="success">
                {readScripts}
              </IconButton>
            </Tooltip> */}
          </Box>

          <Box sx={{ border: "0px solid green", display: { xs: "none", sm: "flex" }, width: "50%", justifyContent: "right", alignItems: "center" }}>
            <Link
              href={(process.env.REACT_APP_ROUTER_BASE || "") + "/listening-rules/"}
              sx={{
                textDecoration: "none",
                fontFamily: "Noto Serif Bengali, serif",
                fontWeight: "bold",
                fontSize: "100%"
              }}
            >
              যাচাইয়ের নিয়মাবলী
            </Link>
          </Box>
          <Box sx={{ border: "0px solid green", display: { xs: "flex", sm: "none" }, width: "25%", justifyContent: "right", alignItems: "center" }}>
            <Link
              href={(process.env.REACT_APP_ROUTER_BASE || "") + "/listening-rules/"}
              sx={{
                textDecoration: "none"
              }}
            >
              <LaunchIcon fontSize="large" />
            </Link>
          </Box>
        </Box>

        {/*............. Upper Part Ends,............................... */}

        {/* ....................Middle part Starts............... */}

        <Box
          sx={{
            width: "100%",
            border: "0px solid blue",
            height: { xs: "92%", sm: "88%" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* .............tab part starts.............. */}
          <Box
            sx={{
              height: { xs: "9%", sm: "9%" },
              width: { xs: "100%", sm: "75%" },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              border: "0px solid black",
              bgcolor: "background.paper",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Autocomplete
              id="eligible-speakers"
              size="small"
              freeSolo
              options={eligibleSpeakers}
              defaultValue={selectedSpeaker}
              // groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option}
              // isOptionEqualToValue={(option: any, value: any) =>
              //   option.title === value.title
              // }
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="বক্তা নির্বাচন করুন / পেস্ট করুন"
                // InputProps={{
                //   ...params.InputProps,
                //   type: 'search',
                // }}
                />
              )}
              onChange={(event: any, value: any, reason: any) =>
                setSelectedSpeaker(value)
              }
              onInputChange={(event: any, value: any, reason: any) => {
                // handleSearchChange(event, value, reason)
              }}
            />

            <SearchSharpIcon
              fontSize="large"
              sx={{ cursor: "pointer" }}
              onClick={async () => {
                setSelectedSpeaker((document.getElementById("eligible-speakers") as HTMLTextAreaElement).value)
              }}
            />
          </Box>

          <Box
            sx={{ border: "0px solid black", height: { xs: "91%", sm: "91%" }, display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <Box sx={{ border: "0px solid black", height: { xs: "91%", sm: "100%" }, width: { xs: "100%", sm: "75%" } }}>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "0px",
                  boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.1);",
                  height: { xs: "91%", sm: "91%" },
                  border: "0px solid red",
                  display: "flex",
                  flexDirection: "column",
                  // gap: 2,
                }}
              >
                {/*............................. scripts render starts............ */}
                <Box
                  sx={{
                    height: { xs: "92%", sm: "92%" },
                    textAlign: "center",
                    border: "0px solid blue",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "auto",
                    paddingX: "10px",
                    // visibility: reRecording || audioInfoList.length < 5 || playingIndex != -1
                    //   ? "visible"
                    //   : "hidden"
                  }}
                >
                  <Typography sx={{ fontSize: { xs: "1rem", sm: "1.5rem", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold" } }}>
                    {playingIndex !== -1 ? (
                      <BoldMiddleText
                        text={scripts[playingIndex].description}
                      />
                    ) : scripts[index] ? (
                      <BoldMiddleText text={scripts[index].description} />
                    ) : null}
                  </Typography>
                </Box>
                {/*............................. scripts render ends............ */}
              </Box>

              {/*................... Play pause button starts........... */}
              <Box
                // disabled={true}
                sx={{
                  border: "0px solid green",
                  width: { xs: "100%", sm: "100%" },
                  height: { xs: "9%", sm: "9%" },
                  display: "flex",
                  visibility: reVerdict
                    ? "visible"
                    : playingIndex === -1 && verdictCount.current < 5
                      ? "visible"
                      : "hidden",
                  justifyContent: "center",
                  alignItems: "center",
                  // boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.1)",
                }}
              >
                <Box
                  sx={{
                    border: "0px solid red",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2.5,
                    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                  <IconButton
                    color="info"
                    disabled={scripts.length == 0 || verdictCount.current >= scripts.length}
                    onClick={() => setOpenSpectrogram(true)}
                  >
                    <GraphicEqIcon fontSize="large" />
                  </IconButton>

                  <Dialog
                    fullScreen
                    open={openSpectrogram}
                    onClose={closeSpectrogram}
                  >
                    <DialogContent sx={{ p: 0, m: 0 }}>
                      <AudioSpectrogram audioUrl={scripts.length == 0 || verdictCount.current >= scripts.length ? "" : scripts[playingIndex !== -1 ? playingIndex : index].audioElement.src} wavesurferInstance={wavesurferInstance} setHasListened={setHasListened} />
                      <Box sx={{ width: "100%", height: "25%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "0px solid black" }}>
                        <Typography sx={{ fontSize: { fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", textAlign: "center", border: "0px solid black" } }}>
                          {playingIndex !== -1 ? (
                            <BoldMiddleText
                              text={scripts[playingIndex].description}
                            />
                          ) : scripts[index] ? (
                            <BoldMiddleText text={scripts[index].description} />
                          ) : null}
                        </Typography>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button color="error" variant="contained" fullWidth onClick={closeSpectrogram} autoFocus sx={{
                        fontWeight: "bold"
                      }}>Close</Button>
                    </DialogActions>
                  </Dialog>

                  <IconButton
                    color="error"
                    disabled={scripts.length == 0}
                    onClick={handleClickReject}
                    onMouseEnter={handleClickReject}
                  >
                    <ClearIcon fontSize="large" />
                  </IconButton>

                  <Menu
                    id="reject-menu"
                    anchorEl={anchorElReject}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    open={Boolean(anchorElReject)}
                    onClose={handleCloseReject}
                    MenuListProps={{ onMouseLeave: handleCloseReject }}
                  >
                    {REJECT_REASONS.map((reason: any) => {
                      return (
                        <MenuItem
                          sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "80%" }}
                          disabled={!hasListened}
                          key={reason}
                          dense
                          onClick={(e: any) => {
                            handleCloseReject(e);
                            handleItemClick("NO", reason);
                          }}
                        >
                          {reason}
                        </MenuItem>
                      );
                    })}
                  </Menu>

                  {
                    isPlaying ? (
                      <>
                        <img
                          src={stopImage}
                          alt="stop_image"
                          onClick={() => {
                            setIsPlaying(false);
                            scripts[
                              playingIndex !== -1 ? playingIndex : index
                            ].audioElement.pause();
                          }}
                          style={{
                            cursor: "pointer",
                            height: "100%",
                            width: "auto",
                          }}
                        />
                      </>
                    ) : (
                      <IconButton
                        color="primary"
                        disabled={scripts.length == 0}
                        sx={{
                          cursor: "pointer",
                          height: "100%",
                          // width: "50px",
                        }}
                        onClick={() => {
                          setIsPlaying(true);
                          playAudio();
                        }}
                      >
                        <PlayCircleFilledOutlinedIcon fontSize="large" />
                      </IconButton>
                    )
                  }

                  <IconButton
                    color="success"
                    disabled={scripts.length == 0}
                    onClick={handleClickAccept}
                    onMouseEnter={handleClickAccept}
                  >
                    <CheckIcon fontSize="large" />
                  </IconButton>

                  <Menu
                    id="accept-menu"
                    anchorEl={anchorElAccept}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    open={Boolean(anchorElAccept)}
                    onClose={handleCloseAccept}
                    MenuListProps={{ onMouseLeave: handleCloseAccept }}
                  >
                    {ACCEPT_REASONS.map((reason: any) => {
                      return (
                        <MenuItem
                          sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "80%" }}
                          disabled={!hasListened}
                          key={reason}
                          dense
                          onClick={(e: any) => {
                            handleCloseAccept(e);
                            handleItemClick("YES", reason);
                          }}
                        >
                          {reason}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Box>
              </Box>
              {/* ........... ................... Play pause button ends........... */}
            </Box>

            {/* ............side bar 'all audio' part starts.............................. */}
            <Box
              sx={{
                height: { xs: "9%", sm: "100%" },
                width: { xs: "100%", sm: "25%" },
                border: "0px solid red",
              }}
            >
              <Box
                className="all audio"
                sx={{
                  height: { xs: "100%", sm: "91%" },
                  width: "100%",
                  border: "0px solid green",
                  boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.1)",
                  display: "flex",
                  flexDirection: { xs: "row", sm: "column" },
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  // variant="h6"
                  sx={{
                    height: { xs: "0%", sm: "8%" },
                    display: { xs: "none", sm: "flex" },
                    fontFamily: "Noto Serif Bengali, serif",
                    fontWeight: "bold",
                    fontSize: "125%",
                    alignItems: "center",
                    // border: "2px solid red"
                  }}
                >
                  আপনার যাচাইসমূহ
                </Typography>

                {scripts.map((script: any, index: number) => {
                  if (script.verdict) {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          // alignContent: "center",
                          alignItems: "center",
                          justifyContent: "space-around",
                          // boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.1)",
                          width: { xs: "16.5%", sm: "100%" },
                          padding: { xs: "0px", sm: "0px" },
                          height: { xs: "100%", sm: "18.4%" },
                          border: "0px solid red",
                        }}
                      >
                        <Box sx={{ display: { xs: "none", sm: "flex" }, width: "25%", alignItems: "center", justifyContent: "center" }}>
                          <Typography color="secondary"
                            sx={{ textAlign: "center", fontWeight: "bold" }}
                          >
                            {index + 1}
                          </Typography>
                        </Box>

                        <Box sx={{ display: { xs: "none", sm: "flex" }, width: "50%", alignItems: "center", justifyContent: "center" }}>
                          <Typography color={script.verdict.split("|")[0] == "YES" ? "success.main" : "error.main"}
                            sx={{ textAlign: "center", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "90%" }}
                          >
                            {script.verdict.split("|")[1]}
                          </Typography>
                        </Box>

                        <Box sx={{ display: { xs: "none", sm: "flex" }, width: "25%", alignItems: "center", justifyContent: "center" }}>
                          <IconButton
                            color="primary"
                            disabled={playingIndex != -1 || isPlaying || reVerdict}
                            onClick={() => {
                              setReVerdict(true);
                              setPlayingIndex(index);
                            }}
                          >
                            <ReplayIcon
                              fontSize="large"
                              sx={{
                                display: { xs: "none", sm: "block" },
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>
                        </Box>

                        {/*..................... For mobile............. */}
                        <Box
                          sx={{
                            width: "100%",
                            // height: "40px",
                            display: { xs: "block", sm: "none" },
                            backgroundColor: "#FFF3E2",
                            border: "0px solid green",
                            // color: "#fff",
                            // borderRadius: "50%",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "18px",
                          }}
                        >
                          <ClickAwayListener
                            onClickAway={() =>
                              setOpenStates((prev: any) =>
                                prev.map((_: any, i: any) =>
                                  i === index ? false : prev[i]
                                )
                              )
                            }
                          >
                            <div>
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                onClose={() =>
                                  setOpenStates((prev: any) =>
                                    prev.map((_: any, i: any) =>
                                      i === index ? false : prev[i]
                                    )
                                  )
                                }
                                open={openStates[index]}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                arrow={true}
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      backgroundColor: "#f6eff5",
                                      paddingLeft: "5px",
                                      paddingRight: "0px",
                                      paddingY: "0px",
                                      '& .MuiTooltip-arrow': {
                                        color: '#f6eff5',
                                      },
                                    }
                                  }
                                }}
                                placement="top" // Set the placement to "top"
                                title={
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      // padding: "5px",
                                    }}
                                  >
                                    {/* <Box sx={{ display: { xs: "none", sm: "flex" }, width: "50%", alignItems: "center", justifyContent: "center" }}> */}
                                    <Typography color={script.verdict.split("|")[0] == "YES" ? "success.main" : "error.main"}
                                      sx={{ textAlign: "center", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "100%" }}
                                    >
                                      {script.verdict.split("|")[1]}
                                    </Typography>
                                    {/* </Box> */}
                                    <IconButton
                                      disabled={playingIndex != -1 || isPlaying || reVerdict}
                                      onClick={() => {
                                        setReVerdict(true);
                                        setPlayingIndex(index);
                                        setOpenStates((prev: any) =>
                                          prev.map((_: any, i: any) =>
                                            i === index ? false : prev[i]
                                          )
                                        );
                                      }}
                                    >
                                      <ReplayIcon
                                        fontSize="large"
                                        color="primary"
                                        sx={{
                                          cursor: "pointer",
                                        }}
                                      />
                                    </IconButton>
                                  </Box>
                                }
                              >
                                <Button
                                  onClick={() => handleTooltipClick(index)}
                                >
                                  {index + 1}
                                </Button>
                              </Tooltip>
                            </div>
                          </ClickAwayListener>
                        </Box>
                      </Box>
                    );
                  }
                })}

                <Box sx={{
                  display: { xs: "flex", sm: "none" },
                  width: { xs: "17.5%", sm: "0%" },
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <IconButton
                    disabled
                    // disabled={playingIndex !== -1 || isPlaying}
                    color="primary"
                    onClick={() => onSubmit()}
                  >
                    <PublishIcon />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ border: "0px solid yellow", height: { xs: "0%", sm: "9%" }, display: { xs: "none", sm: "block" } }}>
                <Button sx={{ height: "100%", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "125%" }}
                  disabled
                  // disabled={playingIndex !== -1 || isPlaying}
                  variant="contained"
                  endIcon={<PublishIcon />}
                  fullWidth={true}
                  onClick={() => onSubmit()}
                >
                  জমা দিন
                </Button>
              </Box>
            </Box>
            {/* ............side bar all audio part ends.............................. */}
          </Box >
        </Box >

        {/* ....................Middle part Ends............... */}
      </Box >
    </>
  );
}

export default ShununComponent;
