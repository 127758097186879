import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isLoggedIn, post, postToPython, setToken, unsetToken } from "../../common/http";
import { ToastFailedMsg, ToastSuccessMsg } from "../../common/toast";
import MenuComponent from "./Menu.Component";
import NavbarResponsive from "./NavbarResponsive";
import PasswordResetModal from "./PasswordResetModal";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import { styled, lighten, darken } from '@mui/system';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Footer from "./Footer";
import { ReactComponent as SukonthoSvg } from './images/sukontho.svg';

import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/Login';
import SendIcon from '@mui/icons-material/Send';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// interface FormDataI {
//   username: string;
//   password: string;
// }

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LogInPage() {
  const [loading, setLoading]: any = useState(false);
  const [open, setOpen] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [matchNewPassword, setMatchNewPassword] = useState(false);

  const history = useHistory();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    // called when jwt changed
    //  if (isLoggedIn()) {
    //
    //      console.log("LOg in Page ",isLoggedIn())
    //      history.push('/reve-crowd-bn')
    //  }
    if (isLoggedIn()) {
      history.push("/reve-crowd-bn-bolun");
    }
  }, []);

  // const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const enterIntoSystem = () => {
    history.push("/reve-crowd-bn-bolun");
    // window.location.reload();
  };

  // const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   unsetToken();

  //   let res = await postToPython("/auth/login", formData);

  //   if (res.status === 200 || res.status === 201) {
  //     let data = await res.json();
  //     console.log("msg", data);

  //     setToken(data.data);
  //     //console.log(token);
  //     ToastSuccessMsg("লগইন সম্পন্ন হয়েছে");
  //     setTimeout(() => enterIntoSystem(), 2000);
  //   } else {
  //     ToastFailedMsg("লগইন সম্পন্ন হয়নি");
  //   }
  // };

  // const [showLoginModal, setShowLoginModal]: any = useState(false);

  // function funcloginmodal(e: any) {
  //   e.preventDefault();
  //   setShowLoginModal(!showLoginModal);
  //   console.log(showLoginModal);
  //   console.log("link was clicked");
  // }

  return (
    <>
      <Backdrop
        sx={{ color: '#f06292', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => { }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{
        height: "100vh", weight: "100vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "top",
        // backgroundColor: "#DFE6F5",
        // backgroundImage: `url(${homepageone})`, backgroundSize: "contain"
        backgroundImage: "linear-gradient(to bottom, #e8f2fc, #FFFFFF, #e8f2fc)"
      }}>
        <Box sx={{ width: "100%", height: { xs: "6.5%", sm: "8%" }, border: "0px solid blue" }}>
          <NavbarResponsive />
        </Box>

        <Box sx={{ width: "100%", height: { xs: "87.5%", sm: "86%" }, border: "0px solid red", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ display: { xs: "none", md: "flex" }, width: { xs: "0%", md: "50%" }, height: "100%", border: "0px solid red", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <SukonthoSvg style={{ width: "50%", height: "auto" }} />
            <Typography noWrap
              sx={{
                fontFamily: "Alkatra, cursive", fontSize: "250%", fontWeight: "bold",
                // letterSpacing: '.3rem',
                color: '#006B43',
                cursor: "pointer"
              }} onClick={() => history.push((process.env.REACT_APP_ROUTER_BASE || "") + "/")}
            >সুকণ্ঠ</Typography>
          </Box>
          <Box sx={{
            display: "flex", flexDirection: "column", width: { xs: "100%", md: "50%" }, height: "100%",
            border: "0px solid green", alignItems: "center", justifyContent: "center",
          }}>
            <h3 style={{ textAlign: "center", paddingBottom: "25px", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", color: "#41253c" }}>
              প্রবেশ করুন
            </h3>
            <form style={{
              width: "75%", border: "0px solid blue", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px", paddingTop: "25px", borderRadius: "25px",
              // backgroundColor: "#e6f2ff",
              backgroundImage: "linear-gradient(to right, #f6eff5, #FFFFFF, #f6eff5)",
              boxShadow: "-8px -8px 8px #41253c, 8px 8px 8px #41253c",
            }}>
              <FormControl fullWidth variant="outlined" sx={{ mb: 3 }} >
                <InputLabel htmlFor="username" sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "110%" }}>ইউজারনেম</InputLabel>
                <OutlinedInput
                  required
                  id="username"
                  type='text'
                  spellCheck={false}
                  onChange={(e: any) => {
                    setUsername(e.target.value);
                    // setUsernameExists(annotatorList.includes(e.target.value))
                  }}
                />
              </FormControl>

              <FormControl fullWidth variant="outlined" sx={{ mb: 3 }} >
                <InputLabel htmlFor="password" sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "110%" }}>পাসওয়ার্ড</InputLabel>
                <OutlinedInput
                  required
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i;
                    // const passwordRegex = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/i;
                    // setValidPassword(passwordRegex.test(e.target.value.toLowerCase()));
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
                <Box sx={{ width: "50%" }}>
                  <Stack sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', m: 0, p: 0 }}>
                    <Button size="small" color="error" disabled={!username} onClick={() => setOpen(true)}
                      sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "100%" }}
                    // disabled={!usernameExists}
                    >
                      পাসওয়ার্ড পরিবর্তন
                    </Button>
                    <Dialog open={open} TransitionComponent={Transition} onClose={() => setOpen(false)}
                    // sx={{ backgroundImage: "linear-gradient(to right, #f6eff5, #FFFFFF, #f6eff5)" }}
                    >
                      <DialogTitle sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "125%", color: "#41253c", textAlign: "center" }}>পাসওয়ার্ড পরিবর্তন</DialogTitle>
                      <DialogContent>
                        {/* <DialogContentText sx={{ mb: 3 }}>
                          Type your New Password below & store it in a safe place so that you do Not forget again !
                        </DialogContentText> */}
                        <form>
                          <FormControl fullWidth variant="outlined" sx={{ mb: 3 }} >
                            <InputLabel htmlFor="newPassword" sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "110%" }}>নতুন পাসওয়ার্ড</InputLabel>
                            <OutlinedInput
                              required
                              id="newPassword"
                              type={showPassword ? 'text' : 'password'}
                              onChange={(e: any) => {
                                setNewPassword(e.target.value);
                                // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i;
                                // const passwordRegex = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/i;
                                // setValidPassword(passwordRegex.test(e.target.value.toLowerCase()));
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>

                          <FormControl fullWidth variant="outlined" sx={{ mb: 3 }} >
                            <InputLabel htmlFor="matchNewPassword" sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "110%" }}>নতুন পাসওয়ার্ড পুনরায় টাইপ করুন</InputLabel>
                            <OutlinedInput
                              required
                              id="matchNewPassword"
                              type={showPassword ? 'text' : 'password'}
                              disabled={!newPassword}
                              onChange={(e: any) => {
                                setMatchNewPassword(e.target.value == newPassword);
                                // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i;
                                // const passwordRegex = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/i;
                                // setValidPassword(passwordRegex.test(e.target.value.toLowerCase()));
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>

                          <Box sx={{ width: "100%", border: "0px solid red", display: "flex", flexDirection: "row", gap: 2, alignItems: "center", justifyContent: "flex-end" }}>

                            <Button variant="contained" color="error"
                              sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "110%", px: "8px", py: "2px" }}
                              onClick={() => {
                                setOpen(false);
                                setNewPassword("");
                                setMatchNewPassword(false);
                              }}>ফিরে যান</Button>

                            <Button type="submit" variant="contained" color="success" endIcon={<SendIcon />}
                              sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "110%", px: "8px", py: "2px" }}
                              disabled={!matchNewPassword}
                              onClick={async (e: any) => {
                                e.preventDefault();
                                setOpen(false);
                                setLoading(true);
                                console.log("RESET PASSWORD !");
                                let myHeaders = new Headers();
                                myHeaders.append("Content-Type", "application/json");

                                let requestBody = JSON.stringify({
                                  "username": username,
                                  "password": newPassword
                                });
                                // console.log(requestBody)

                                try {
                                  let requestOptions = {
                                    method: 'POST',
                                    headers: myHeaders,
                                    body: requestBody,
                                    // redirect: 'follow'
                                  };

                                  let response = await fetch("https://amarkantho.revesoft.com:5901/reset-password/", requestOptions)
                                  setLoading(false)
                                  let status = response.status
                                  if (status == 200 || status == 201) {
                                    let responseBody = await response.json()
                                    ToastSuccessMsg(responseBody.message)
                                  } else if (status == 422) {
                                    ToastFailedMsg("Field Missing !")
                                  } else {
                                    let responseBody = await response.json()
                                    ToastFailedMsg(responseBody.message)
                                  }
                                }
                                catch (err: any) {
                                  console.log("ERROR ! ", err.message)
                                  ToastFailedMsg(`ERROR ! ${err.message}`)
                                }
                              }}>
                              জমা দিন
                            </Button>
                          </Box>
                        </form>
                      </DialogContent>
                      {/* <DialogActions></DialogActions> */}
                    </Dialog>
                    <Button size="small" color="primary"
                      sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "100%" }}
                      onClick={() => window.location.replace(window.location.origin + `/sign-up`)} >
                      কোন অ্যাকাউন্ট নেই? নিবন্ধন করুন
                    </Button>
                  </Stack>
                </Box>
                <Box sx={{ width: "50%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                  <Button type="submit" variant="contained" endIcon={<LoginIcon />} color="success"
                    sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "110%", px: "8px", py: "2px" }}
                    onClick={async (e: any) => {
                      e.preventDefault();
                      console.log("LOGIN CLICKED !");

                      setLoading(true);
                      let myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/json");

                      let requestBody = JSON.stringify({
                        "username": username,
                        "password": password
                      });
                      // console.log(requestBody)

                      try {
                        let requestOptions = {
                          method: 'POST',
                          headers: myHeaders,
                          body: requestBody,
                          // redirect: 'follow'
                        };

                        let response = await fetch("https://amarkantho.revesoft.com:5901/login/", requestOptions)
                        setLoading(false)
                        let status = response.status
                        if (status == 200 || status == 201) {
                          let data = await response.json();
                          console.log("msg", data);

                          setToken(data.data);
                          ToastSuccessMsg("লগইন সম্পন্ন হয়েছে");
                          setTimeout(() => enterIntoSystem(), 2000);
                        } else if (status == 422) {
                          ToastFailedMsg("Field Missing !")
                        } else {
                          let responseBody = await response.json()
                          console.log("ERROR! ", responseBody)
                          ToastFailedMsg(responseBody.message)
                        }
                      }
                      catch (err: any) {
                        console.log("ERROR ! ", err.message)
                        ToastFailedMsg(`ERROR ! ${err.message}`)
                      }
                    }}>
                    প্রবেশ
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>

        <Box sx={{ display: "flex", width: "100%", height: "6%", border: "0px solid blue" }}>
          <Footer />
        </Box>
      </Box>
    </>
  );
}
