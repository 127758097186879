import { useEffect, useState } from "react";
import { ReactNotifications } from "react-notifications-component";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.scss";
import { isLoggedIn } from "./common/http";

import CrowdDashBoard from "./pages/crowd-source/Dashboard.page";
import LogInPage from "./pages/crowd-source/LogIn.page";
import SignUpPage from "./pages/crowd-source/SignUp.page";
import AboutPage from "./pages/crowd-source/About.page";

import IndexBolun from "./pages/crowd-source/IndexBolun";
import ReadingRulesPage from "./pages/crowd-source/ReadingRules.page";

import IndexShunun from "./pages/crowd-source/IndexShunun";
import ListeningRulesPage from "./pages/crowd-source/ListeningRules.page";

import Userdashboard from "./pages/crowd-source/Userdashboard";

function App() {
  const [loginStatus, setLoginStatus]: any = useState({
    loginStatus: isLoggedIn(),
  });

  useEffect(() => {

  }, []);

  return (
    <div className="App">
      <ReactNotifications />
      <Router basename={process.env.REACT_APP_ROUTER_BASE || ""}>
        <Switch>
          <>
            <Route exact path="/" component={CrowdDashBoard} />
            <Route path="/sign-up/" component={SignUpPage} />
            <Route path="/log-in/" component={LogInPage} />
            <Route path="/about/" component={AboutPage} />

            <Route path="/reve-crowd-bn-bolun/" component={IndexBolun} />
            <Route path="/reading-rules/" component={ReadingRulesPage} />

            <Route path="/reve-crowd-bn-shunun/" component={IndexShunun} />
            <Route path="/listening-rules/" component={ListeningRulesPage} />

            <Route path="/user-dashboard/" component={Userdashboard} />
          </>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
