import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import InventoryIcon from "@mui/icons-material/Inventory";
import LaunchIcon from "@mui/icons-material/Launch";
import MicIcon from "@mui/icons-material/Mic";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import PublishIcon from "@mui/icons-material/Publish";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, Link, Typography, Stack } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import * as rdd from "react-device-detect";
import { useGeolocated } from "react-geolocated";
import { decodeToken } from "../../common/http";
import { ToastFailedMsg, ToastSuccessMsg, ToastWarningMsg } from "../../common/toast";
import BoldMiddleText from "./BoldMiddleText";
import { getCrowdScripts } from "./crowd_source.service";
import startImage from "./images/start.svg";
import stopImage from "./images/stop.gif";
import FastForwardIcon from '@mui/icons-material/FastForward';


export default function BolunComponent(props: any) {
  let [timeLeft, setTimeLeft]: any = useState(30);
  let [currentInterval, setCurrentInterval]: any = useState(null);
  let [remainingScripts, setRemainingScripts]: any = useState(0);
  let [readScripts, setReadScripts]: any = useState(0);
  const [isRecording, setIsRecording]: any = useState(false);
  let tabs = [
    "NATURAL",
    // "HOMOGRAPH",
    "INTERROGATIVE",
    "EXCLAMATORY",
    // "NEGATIVE",
    // "OPTATIVE",
  ];
  const storedTabValue = localStorage.getItem("tabValue");
  const initialTabValue = storedTabValue ? parseInt(storedTabValue) : 0;

  const [tabValue, setTabValue] = useState(initialTabValue);
  const [tooltipStates, setTooltipStates]: any = useState(Array(5).fill(false));
  const [scripts, setScripts]: any = useState([]);
  const [index, setIndex] = useState<number>(0);
  const [stream, setStream]: any = useState(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioRecorder, setAudioRecorder]: any = useState(null);
  const [playingIndex, setPlayingIndex] = useState<number>(-1);
  const [audioInfoList, setAudioInfoList]: any = useState([]);
  const [reRecording, setReRecording]: any = useState(false);
  // const { coords, isGeolocationAvailable, isGeolocationEnabled } =
  //   useGeolocated({
  //     positionOptions: {
  //       enableHighAccuracy: true,
  //     },
  //   });
  const [skippedList, setSkippedList]: any = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  let fmt = useRef("");

  const userInfo: any = useRef(null);

  useEffect(() => {
    userInfo.current = decodeToken();
    console.log(userInfo.current);
    let newValue = localStorage.getItem("tabValue");
    if (newValue === null) {
      setTabValue(0);
    } else {
      setTabValue(parseInt(newValue));
    }
    (async () => {
      let new_stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setStream(new_stream);
    })();
  }, []);

  useEffect(() => {
    console.log("tab value changed.................................");
    ResetAll();
  }, [tabValue]);

  const ResetAll = () => {
    getScripts(tabs[tabValue]);
    setSkippedList([]);
    // audioRef.current = null;
    setTooltipStates(Array(5).fill(false));
    setAudioRecorder(null);
    setPlayingIndex(-1);
    setAudioInfoList([]);
    setReRecording(false);
    setIndex(0);
  };

  useEffect(() => {
    console.log("the audio blob list ......", audioInfoList);
  }, [audioInfoList]);

  useEffect(() => {
    // console.log("Seconds Left", timeLeft);
    if (timeLeft <= 0) {
      setIsRecording(false);
      clearInterval(currentInterval);
      audioRecorder.stop();
      setTimeLeft(30);
    }
  }, [timeLeft]);

  const startRecording = async (num = -1) => {
    try {
      const mediaRecorder = new MediaRecorder(stream);
      setAudioRecorder(mediaRecorder);
      const chunks: Blob[] = [];

      mediaRecorder.ondataavailable = (e:any) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
          // console.log("in avaialbe....................................");
        }
      };

      mediaRecorder.onstop = async (e: any) => {
        setTimeLeft(0);
        const mimeType = mediaRecorder.mimeType;
        console.log("mime type ", mimeType);
        fmt.current = mimeType;
        const audioBlob = new Blob(chunks, { type: mimeType });
        let temp = {
          audio: audioBlob,
          index: num === -1 ? index : num,
          scriptId: scripts[num === -1 ? index : num].id,
        };
        if (num === -1) {
          setAudioInfoList([...audioInfoList, temp]);
        } else {
          setAudioInfoList((prevAudioInfoList: any) => {
            const updatedList = [...prevAudioInfoList];
            const position = updatedList.findIndex(
              (audioInfo) => audioInfo.index === num
            );
            if (position !== -1) {
              updatedList[position] = temp;
            }
            return updatedList;
          });
        }

        if (reRecording) {
          console.log("in stop.............");
          setPlayingIndex(-1);
          setReRecording(false);
        } else {
          if (audioInfoList.length < 5) {
            setIndex((prevIndex) => prevIndex + 1);
          }
        }
      };

      mediaRecorder.start();
      setIsRecording(true);

      setCurrentInterval(setInterval(function () {
        // console.log("Inside Interval")
        setTimeLeft((prevCount: any) => Math.max(prevCount - 1, 0))
      }, 1000))


    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const playAudio = (num: number) => {
    console.log("in play the index is......", num);
    if (audioInfoList.length > 0) {
      let audioData: any = null;
      audioInfoList.forEach((tempAudio: any) => {
        if (tempAudio.index === num) {
          audioData = tempAudio.audio;
        }
      });

      const audioUrl = URL.createObjectURL(audioData);
      console.log("the audio url.......");
      if (audioRef.current) {
        audioRef.current.src = audioUrl;
        audioRef.current.play();
        audioRef.current.onended = () => {

          setTooltipStates((prev: any) =>
            prev.map((_: any, i: any) =>
              false
            )
          )

          setPlayingIndex(-1);
        };
      }
    }
  };
  const stopAudio = () => {
    // console.log("outer................");
    if (audioRef.current) {
      // console.log("inner.................");
      audioRef.current.pause();
      // audioRef.current.currentTime = 0; // Reset the audio to the beginning
    }
    setTooltipStates((prev: any) =>
      prev.map((_: any, i: any) =>
        i === playingIndex ? false : prev[i]
      )
    )

    setPlayingIndex(-1);
  };

  const handleTabClick = (event: React.SyntheticEvent, newValue: number) => {
    console.log("HANDLE TAB CLICK: ", newValue);
    localStorage.setItem("tabValue", newValue.toString());
    setTabValue(newValue);
  };
  function strToObj(str: string) {
    var obj = {
      reason: undefined,
    };
    if (str && typeof str === "string") {
      var objStr = str.match(/\{(.)+\}/g);
      eval("obj =" + objStr);
      /*stringify("obj ="+objStr);*/
    }
    return obj;
  }

  const getScripts = async (type: any) => {
    console.log("caLLED................");
    if (!userInfo.current) {
      ToastFailedMsg("Please Login First !")
      // let res = await getCrowdScripts(0, type);

      // if (res.status === 200 || res.status === 201) {
      //   let { data, remaining_scripts, read_scripts } = await res.json();
      //   setScripts(data);
      //   setRemainingScripts(remaining_scripts);
      //   setReadScripts(read_scripts);
      //   console.log(data, remaining_scripts, read_scripts);
      // }
    } else {
      let res = await getCrowdScripts(userInfo.current.userId, type);
      // console.log("response.............", res);
      if (res.status === 200 || res.status === 201) {
        let { data, remaining_scripts, read_scripts } = await res.json();
        setScripts(data);
        setRemainingScripts(remaining_scripts);
        setReadScripts(read_scripts);
        console.log(data, remaining_scripts, read_scripts);
      }
    }
  };

  const onSubmit = async () => {
    setOpenBackdrop(true);
    console.log("INSIDE SUBMIT .........................")

    let useragent: any = {};
    let geoData: any = {};
    let deviceString: any = {};

    useragent.isMobile = rdd.isMobile;
    useragent.isAndroid = rdd.isAndroid;
    useragent.isIOS = rdd.isIOS;
    useragent.isIOS13 = rdd.isIOS13;
    useragent.isDesktop = rdd.isDesktop;
    useragent.isWindows = rdd.isWindows;
    useragent.isMacOs = rdd.isMacOs;
    useragent.isChrome = rdd.isChrome;
    useragent.isFirefox = rdd.isFirefox;
    useragent.isEdge = rdd.isEdge;
    useragent.isSamsungBrowser = rdd.isSamsungBrowser;
    useragent.isBrowser = rdd.isBrowser;
    useragent.mobileVendor = rdd.mobileVendor;
    useragent.mobileModel = rdd.mobileModel;
    useragent.osName = rdd.osName;
    useragent.osVersion = rdd.osVersion;
    useragent.fullBrowserVersion = rdd.fullBrowserVersion;

    // if (!isGeolocationAvailable) {
    //   console.log("Your browser does not support Geolocation");
    //   setOpenBackdrop(false);
    //   ToastFailedMsg("Geolocation Not Available !")
    // } else if (!isGeolocationEnabled) {
    //   console.log("Your did Not allow Location");
    //   setOpenBackdrop(false);
    //   ToastFailedMsg("Geolocation Not Enabled !")
    // } else {
      // geoData.latitude = coords?.latitude;
      // geoData.longitude = coords?.longitude;
      // geoData.altitude = coords?.altitude;
      // geoData.heading = coords?.heading;
      // geoData.speed = coords?.speed;

      geoData.latitude = null;
      geoData.longitude = null;
      geoData.altitude = null;
      geoData.heading = null;
      geoData.speed = null;

      let details: any = {};
      if (!navigator.mediaDevices?.enumerateDevices) {
        console.log("enumerateDevices() not supported.");
        setOpenBackdrop(false);
        ToastFailedMsg("Micrphone Details could Not be found !")
      } else {
        let submitted = false;
        try {
          let devices = await navigator.mediaDevices.enumerateDevices()
          for await (const device of devices) {
            if (
              !(
                device.deviceId === "default" ||
                device.deviceId === "communications"
              ) &&
              device.kind === "audioinput"
            ) {
              console.log("i want this device", device);

              deviceString[device.kind] = device.label;

              details.userAgent = useragent;
              details.geoLocation = geoData;
              details.device = deviceString;

              if (userInfo.current) {
                if (!submitted) {
                  let crowdDataNew: any = new FormData();

                  let finalScriptIdList: any = [];
                  audioInfoList.forEach((element: any) => {
                    // finalAudioList.push(element.audio);
                    console.log(element.audio);
                    crowdDataNew.append("audios", element.audio);
                    finalScriptIdList.push(element.scriptId);
                  });
                  crowdDataNew.append("speaker_id", userInfo.current.userId);
                  crowdDataNew.append("fmt", fmt.current);

                  crowdDataNew.append(
                    "scripts",
                    JSON.stringify(finalScriptIdList)
                  );
                  crowdDataNew.append(
                    "skipped_list",
                    JSON.stringify(skippedList)
                  );
                  crowdDataNew.append("details", JSON.stringify(details));
                  console.log("fmt", fmt.current);

                  let requestOptions = {
                    method: "POST",
                    body: crowdDataNew,
                  };

                  let response = await fetch(
                    "https://amarkantho.revesoft.com:5911/submit-audio/",
                    requestOptions
                  )

                  if (response.status == 200 || response.status == 201) {
                    let result = await response.json()
                    setOpenBackdrop(false);
                    console.log(result);
                    ToastSuccessMsg(result.message);
                    ResetAll();
                  } else {
                    let error = await response.json()
                    setOpenBackdrop(false);
                    console.log("error", error);
                    ToastFailedMsg(error.message);
                  }
                  submitted = true;
                }
              } else {
                setOpenBackdrop(false);
                ToastFailedMsg("Please Login First !")
              }
            }
          }
        } catch (err: any) {
          console.error(`${err.name}: ${err.message}`);
          ToastFailedMsg(err.message);
        }
      }
    // }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          border: "0px solid green",
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", sm: "90%" },
          height: { xs: "100%", sm: "100%" },
          mx: "auto",
        }}
      >
        {/*............. Upper Part Starts,............................... */}
        <Box
          sx={{
            border: "0px solid green",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: { xs: "8%", sm: "12%" },
            width: "100%",
            marginBottom: { xs: "0px", sm: "10px" }
          }}
        >
          <Box sx={{ border: "0px solid green", display: "flex", width: { xs: "25%", sm: "25%" }, justifyContent: "left", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton onClick={() =>
                window.location.replace(
                  window.location.origin + "/reve-crowd-bn-bolun/"
                )
              }>
                <MicIcon color="error" fontSize="large" />
              </IconButton>
              <Typography
                sx={{
                  color: "#6F7D99",
                  fontWeight: "bold",
                  display: { xs: "none", sm: "block" },
                  fontFamily: "Noto Serif Bengali, serif"
                }}
              >
                বলুন
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() =>
                  window.location.replace(
                    window.location.origin + "/reve-crowd-bn-shunun/"
                  )
                }
              >
                <HeadphonesIcon fontSize="large" color="primary" />
              </IconButton>
              <Typography
                sx={{
                  color: "#6F7D99",
                  fontWeight: "bold",
                  display: { xs: "none", sm: "block" },
                  fontFamily: "Noto Serif Bengali, serif"
                }}
              >
                শুনুন
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", width: { xs: "50%", sm: "25%" }, justifyContent: "center", alignItems: "center", gap: 2, border: "0px solid green" }}>
            <Tooltip arrow={true} title="পড়া বাকী" componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.black',
                  '& .MuiTooltip-arrow': {
                    color: 'common.black',
                  },
                  color: "#FFF",
                  fontFamily: "Noto Serif Bengali, serif",
                  fontWeight: "bold",
                  fontSize: "75%"
                }
              }
            }}>
              <IconButton sx={{
                p: 0, m: 0, fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
                fontSize: "100%"
              }} color="error">
                {remainingScripts}
              </IconButton>
            </Tooltip>
            <Tooltip arrow={true} title="পড়ে ফেলেছেন" componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.black',
                  '& .MuiTooltip-arrow': {
                    color: 'common.black',
                  },
                  color: "#FFF",
                  fontFamily: "Noto Serif Bengali, serif",
                  fontWeight: "bold",
                  fontSize: "75%"
                }
              }
            }}>
              <IconButton sx={{
                p: 0, m: 0, fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
                fontSize: "100%"
              }} color="success">
                {readScripts}
              </IconButton>
            </Tooltip>
          </Box>

          <Box sx={{ border: "0px solid green", display: { xs: "none", sm: "flex" }, width: "50%", justifyContent: "right", alignItems: "center" }}>
            <Link
              href={(process.env.REACT_APP_ROUTER_BASE || "") + "/reading-rules/"}
              sx={{
                textDecoration: "none",
                fontFamily: "Noto Serif Bengali, serif",
                fontWeight: "bold",
                fontSize: "100%"
              }}
            >
              পড়ার নিয়মাবলী
            </Link>
          </Box>
          <Box sx={{ border: "0px solid green", display: { xs: "flex", sm: "none" }, width: "25%", justifyContent: "right", alignItems: "center" }}>
            <Link
              href={(process.env.REACT_APP_ROUTER_BASE || "") + "/reading-rules/"}
              sx={{
                textDecoration: "none"
              }}
            >
              <LaunchIcon fontSize="large" />
            </Link>
          </Box>
        </Box>

        {/*............. Upper Part Ends,............................... */}

        {/* ....................Middle part Starts............... */}

        <Box
          sx={{
            width: "100%",
            border: "0px solid blue",
            height: { xs: "92%", sm: "88%" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* .............tab part starts.............. */}
          <Box
            sx={{
              height: { xs: "9%", sm: "9%" },
              width: { xs: "100%", sm: "75%" },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              border: "0px solid black",
              bgcolor: "background.paper",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabClick}
              textColor={tabValue % 2 !== 0 ? "primary" : "secondary"}
              indicatorColor={tabValue % 2 !== 0 ? "primary" : "secondary"}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab} sx={{ fontWeight: "bold" }} />
              ))}
            </Tabs>
          </Box>
          {/* .............tab part ends.............. */}

          <Box
            sx={{ border: "0px solid black", height: { xs: "91%", sm: "91%" }, display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <Box sx={{ border: "0px solid black", height: { xs: "91%", sm: "100%" }, width: { xs: "100%", sm: "75%" } }}>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "0px",
                  boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.1);",
                  height: { xs: "91%", sm: "91%" },
                  border: "0px solid red",
                  display: "flex",
                  flexDirection: "column",
                  // gap: 2,
                }}
              >
                <Box
                  // className="reason"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "0px solid green",
                    height: { xs: "8%", sm: "8%" },
                    paddingY: 1,
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ textAlign: "left", width: "25%", border: "0px solid green" }}>
                    <Button variant="text" color={timeLeft > 5 ? "success" : "error"} startIcon={timeLeft > 5 ? <HourglassTopIcon /> : <HourglassBottomIcon />} sx={{ fontWeight: "bold", fontSize: "125%" }}>
                      {timeLeft}
                    </Button>
                  </Box>
                  {/* ...............reason starts..................... */}

                  <Typography color="error" sx={{
                    textAlign: "center", width: "50%", border: "0px solid red", fontFamily: "Noto Serif Bengali, serif",
                    fontWeight: "bold",
                    fontSize: "125%"
                  }}>
                    {scripts[index]
                      ? strToObj(scripts[index].details).reason
                      : null}
                  </Typography>

                  {/* ...........reason ends......................... */}
                  {/* ............skip starts................. */}

                  <Box sx={{ width: "25%", textAlign: "right" }}>
                    <Button variant="text" disabled={isRecording} color="error" sx={{
                      visibility: strToObj(
                        scripts && scripts[index] && scripts[index].details
                      ).reason
                        ? "visible"
                        : "hidden"
                    }} onClick={() => {
                      setSkippedList([...skippedList, scripts[index].id]);
                      if (audioInfoList.length < 5) {
                        setIndex((prevIndex) => prevIndex + 1);
                      }
                      setReRecording(false);
                      setPlayingIndex(-1);
                    }} endIcon={<FastForwardIcon />}>
                      <Typography sx={{
                        display: { xs: "none", sm: "block" }, fontFamily: "Noto Serif Bengali, serif",
                        fontWeight: "bold",
                        fontSize: "125%"
                      }}>
                        এড়িয়ে যান
                      </Typography>
                    </Button>
                  </Box>

                  {/* ............skip ends................. */}
                </Box>

                {/*............................. scripts render starts............ */}
                <Box
                  sx={{
                    height: { xs: "92%", sm: "92%" },
                    textAlign: "center",
                    border: "0px solid blue",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "auto",
                    paddingX: "10px",
                    // visibility: reRecording || audioInfoList.length < 5 || playingIndex != -1
                    //   ? "visible"
                    //   : "hidden"
                  }}
                >
                  <Typography
                    color={
                      strToObj(
                        playingIndex !== -1
                          ? scripts &&
                          scripts[playingIndex] &&
                          scripts[playingIndex].details
                          : scripts && scripts[index] && scripts[index].details
                      ).reason
                        ? "error"
                        : "initial"
                    }
                    sx={{ fontSize: { xs: "1.3rem", sm: "1.8rem" }, fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold" }}
                  >
                    {playingIndex !== -1 ? (
                      <BoldMiddleText
                        text={scripts[playingIndex].description}
                      />
                    ) : scripts[index] && audioInfoList.length < 5 ? (
                      <BoldMiddleText text={scripts[index].description} />
                    ) : "আপনার অডিওসমূহ জমা দিন"}
                  </Typography>
                </Box>
                {/*............................. scripts render ends............ */}
              </Box>

              {/*................... Play pause button starts........... */}
              <Box
                // disabled={true}
                sx={{
                  border: "0px solid green",
                  height: { xs: "9%", sm: "9%" },
                  display: "flex",
                  visibility: reRecording
                    ? "visible"
                    : playingIndex === -1 && audioInfoList.length < 5
                      ? "visible"
                      : "hidden",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.1)",
                }}
              >
                {" "}
                {isRecording ? (
                  <>
                    <img
                      src={stopImage}
                      alt="stop_image"
                      onClick={() => {
                        // setIsRecording(false);
                        audioRecorder.stop();
                      }}
                      style={{
                        cursor: "pointer",
                        height: "100%",
                        width: "auto",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={startImage}
                      alt="start_image"
                      onClick={() => {
                        if (reRecording) {
                          console.log("in start button..................");
                          startRecording(playingIndex);
                        } else {
                          startRecording();
                        }
                        // setIsRecording(true);
                      }}
                      style={{
                        cursor: "pointer",
                        height: "100%",
                        width: "auto",
                      }}
                    />
                  </>
                )}
              </Box>
              {/* ........... ................... Play pause button ends........... */}
            </Box>

            {/* ............side bar 'all audio' part starts.............................. */}
            <Box
              sx={{
                height: { xs: "9%", sm: "100%" },
                width: { xs: "100%", sm: "25%" },
                border: "0px solid red",
              }}
            >
              <Box
                className="all audio"
                sx={{
                  height: { xs: "100%", sm: "91%" },
                  width: "100%",
                  border: "0px solid green",
                  boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.1)",
                  display: "flex",
                  flexDirection: { xs: "row", sm: "column" },
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  // variant="h6"
                  sx={{
                    height: { xs: "0%", sm: "8%" },
                    display: { xs: "none", sm: "flex" },
                    fontFamily: "Noto Serif Bengali, serif",
                    fontWeight: "bold",
                    fontSize: "125%",
                    alignItems: "center",
                    // border: "2px solid red"
                  }}
                >
                  আপনার রেকর্ডসমূহ
                </Typography>
                <audio ref={audioRef} style={{ display: "none" }} />

                {audioInfoList.map((audioInfo: any, index: number) => (
                  <Box
                    key={audioInfo.index}
                    sx={{
                      display: "flex",
                      // alignContent: "center",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.1)",
                      width: { xs: "16.5%", sm: "100%" },
                      padding: { xs: "0px", sm: "0px" },
                      height: { xs: "100%", sm: "18.4%" },
                      border: "0px solid red",
                    }}
                  >
                    <Typography color="secondary"
                      sx={{ display: { xs: "none", sm: "block" }, textAlign: "center", fontWeight: "bold", fontSize: "150%" }}
                    >
                      {index + 1}
                    </Typography>

                    {playingIndex === audioInfo.index && !reRecording ? (
                      <IconButton
                        color="error"
                        sx={{
                          display: { xs: "none", sm: "block" }
                        }}
                        disabled={isRecording || reRecording}
                        onClick={() => {
                          stopAudio();
                        }}
                      >
                        <PauseCircleFilledIcon
                          fontSize="large"

                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="success"
                        sx={{
                          display: { xs: "none", sm: "block" }
                        }}
                        disabled={isRecording || reRecording}
                        onClick={() => {
                          playAudio(audioInfo.index);
                          setPlayingIndex(audioInfo.index);
                        }}
                      >
                        <PlayCircleFilledIcon
                          fontSize="large"

                        />
                      </IconButton>
                    )}

                    <IconButton
                      color="primary"
                      sx={{
                        display: { xs: "none", sm: "block" }
                      }}
                      disabled={isRecording || playingIndex != -1}
                      onClick={() => {
                        setPlayingIndex(audioInfo.index);
                        setReRecording(true);
                      }}
                    >
                      <ReplayIcon
                        fontSize="large"

                      />
                    </IconButton>

                    <Box
                      sx={{
                        width: "100%",
                        // height: "40px",
                        display: { xs: "block", sm: "none" },
                        backgroundColor: "#FFF3E2",
                        border: "0px solid green",
                        // color: "#fff",
                        // borderRadius: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "18px",
                      }}
                    >
                      <ClickAwayListener onClickAway={() =>
                        setTooltipStates((prev: any) =>
                          prev.map((_: any, i: any) =>
                            i === index ? false : prev[i]
                          )
                        )
                      }>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() =>
                              setTooltipStates((prev: any) =>
                                prev.map((_: any, i: any) =>
                                  i === index ? false : prev[i]
                                )
                              )
                            }
                            open={tooltipStates[index]}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            arrow={true}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "rgba(14, 9, 24, 1)",
                                }
                              }
                            }}
                            placement="top" // Set the placement to "top"
                            title={
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 2,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  // padding: "5px",
                                }}
                              >
                                {playingIndex === audioInfo.index &&
                                  !reRecording ? (
                                  <IconButton
                                    color="error"
                                    disabled={isRecording}
                                    onClick={() => {
                                      stopAudio();
                                    }}
                                  >
                                    <PauseCircleFilledIcon
                                      fontSize="large"

                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    color="success"
                                    disabled={isRecording}
                                    onClick={() => {
                                      playAudio(audioInfo.index);
                                      setPlayingIndex(audioInfo.index);
                                    }}
                                  >
                                    <PlayCircleFilledIcon
                                      fontSize="large"

                                    />
                                  </IconButton>
                                )}
                                <IconButton
                                  color="primary"
                                  disabled={isRecording}
                                  onClick={() => {
                                    setTooltipStates((prev: any) =>
                                      prev.map((_: any, i: any) =>
                                        i === index ? false : prev[i]
                                      )
                                    )

                                    setPlayingIndex(audioInfo.index);
                                    setReRecording(true);
                                  }}
                                >
                                  <ReplayIcon fontSize="large" />
                                </IconButton>
                              </Box>
                            }
                          >
                            <Button onClick={() =>
                              setTooltipStates((prev: any) =>
                                prev.map((state: any, i: any) =>
                                  i === index ? !state : false
                                )
                              )
                            }>{index + 1}</Button>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </Box>
                  </Box>
                ))}
                <Box sx={{
                  display: { xs: "flex", sm: "none" },
                  width: { xs: "17.5%", sm: "0%" },
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <IconButton
                    disabled
                    // disabled={isRecording || playingIndex !== -1 || audioInfoList.length == 0}
                    color="primary"
                    onClick={() => onSubmit()}
                  >
                    <PublishIcon />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ border: "0px solid yellow", height: { xs: "0%", sm: "9%" }, display: { xs: "none", sm: "block" } }}>
                <Button sx={{ height: "100%", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontSize: "125%" }}
                  disabled
                  // disabled={isRecording || playingIndex !== -1 || audioInfoList.length == 0}
                  variant="contained"
                  endIcon={<PublishIcon />}
                  fullWidth={true}
                  onClick={() => onSubmit()}
                >
                  জমা দিন
                </Button>
              </Box>
            </Box>
            {/* ............side bar all audio part ends.............................. */}
          </Box>
        </Box>

        {/* ....................Middle part Ends............... */}
      </Box>
    </>
  );
}
