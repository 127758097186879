import React from "react";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import mulpata from "./images/stts/mulpata.png";
import bangla_logo from "./images/Bangla_Logo.png";
import bccl from "./images/bccl_ogo.png";
import ict from "./images/ict_logo.png";
import MenuComponent from "./Menu.Component";
import NavbarResponsive from "./NavbarResponsive";
import Footer from "./Footer";
import homepageone from "./images/homepageone.png"

export default function AboutPage() {
  return (
    <Box sx={{
      height: "100vh", weight: "100vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "top",
      // backgroundColor: "#DFE6F5",
      backgroundImage: `url(${homepageone})`, backgroundSize: 'contain'
    }}>
      <Box sx={{ width: "100%", height: { xs: "6.5%", sm: "10%" }, border: "0px solid blue" }}>
        <NavbarResponsive />
      </Box>
      <Box sx={{ width: { xs: "100%", sm: "50%" }, height: { xs: "87.5%", sm: "84%" }, border: "0px solid red", mx: "auto" }}>
        <Typography variant="h4" sx={{ fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold", fontStyle: "italic", py: 4, textAlign: "center" }} >
          কেন <span style={{ fontFamily: "Alkatra, cursive", color: "#006B43", paddingLeft: "5px" }}>সুকণ্ঠ</span> ?
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "125%", textAlign: "justify" }}>
          গণপ্রজাতন্ত্রী বাংলাদেশ সরকারের ডাক, টেলিযোগাযোগ ও তথ্য প্রযুক্তি মন্ত্রণালয়ের অধীনে গবেষণা ও উন্নয়নের মাধ্যমে তথ্য প্রযুক্তিতে বাংলা ভাষা সমৃদ্ধিকরণ প্রকল্পের অধীনে রিভ সিস্টেমস লিমিটেড (Reve Systems Ltd.) বাংলা স্পিচ টু টেক্সট (এসটিটি) ও টেক্সট টু স্পিচ (টিটিএস) সফটওয়্যার তৈরির কাজ করছে।
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "125%", textAlign: "justify" }}>
          এই প্রকল্পের লক্ষ্য ও উদ্দেশ্য হচ্ছে, আন্তর্জাতিক পরিসরে নেতৃস্থানীয় ভাষা হিসেবে বাংলাকে প্রতিষ্ঠিত করা। এই প্রকল্পের আওতায় সংগৃহীত উপাত্ত অন্য কোনো প্রকল্পে বা অন্য কোনো উদ্দেশ্যে ব্যবহৃত হবে না। এই ডেটাসেটে তথ্যপ্রদানকারীর ব্যক্তিগত তথ্য যেমন নাম, ঠিকানা ইত্যাদি প্রকাশ করা হবে না। তবে বয়স, নারী/পুরুষ, আঞ্চলিক/স্থানের মেটাডেটা থাকবে। ভবিষ্যতে এই ডেটা মন্ত্রণালয় প্রচলিত বিধি, রীতি ও গবেষণার আইন অনুসারে প্রকাশ করতে পারবে।
        </Typography>
      </Box>
      <Box sx={{ display: "flex", width: "100%", height: "6%", border: "0px solid blue" }}>
        <Footer />
      </Box>
    </Box>
  );
}
